<script>
import { mapState, mapActions } from 'vuex'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
   components: {
      AppTimeline,
      AppTimelineItem,
   },
   data() {
      return {
         imageSrc: null,
      }
   },
   computed: {
      ...mapState({
         item: state => state.Mppd.item,
      }),
      isLoading() {
         return this.$store.state.isLoading
      },
      getVariant() {
         return status => {
            let value = ''

            if (status === 'Selesai') {
               value = 'success'
            } else {
               value = 'danger'
            }

            return value
         }
      },
   },

   watch: {
      isLoading(val) {
         if (val) {
            this.$swal({ text: 'Loading', allowOutsideClick: false })
            this.$swal.showLoading()
         } else {
            this.$swal.close()
         }
      },
   },
   mounted() {
      console.log(this.item)
      this.getPhotoFromApi(this.item.id)
   },
   methods: {
      ...mapActions({
         getPhoto: 'Mppd/photo',
      }),
      getPhotoFromApi(id) {
         this.getPhoto(id).then(
            async res => {
               // const blob = await res.blob()

               // this.imageSrc = URL.createObjectURL(blob);
               const blob = new Blob([res.data])
               this.imageSrc = URL.createObjectURL(blob)
            },
            () => { },
         )
      },
   },
}
</script>

<template>
   <div>

      <b-row>
         <b-col lg="6" md="12">
            <b-card title="Profil Pemohon">
               <img :src="imageSrc" class="img-fluid rounded mx-auto d-block img-thumbnail mb-2" width="200px">
               <table class="table b-table table-sm">
                  <tbody>
                     <tr role="row" class="">
                        <td class="text-left">Nama</td>
                        <td class="text-left"><b>{{ item.nama }}</b></td>
                     </tr>
                     <tr role="row" class="">
                        <td class="text-left">NIK</td>
                        <td class="text-left">{{ item.nik }}</td>
                     </tr>
                     <tr role="row" class="">
                        <td class="text-left">Jenis Kelamin</td>
                        <td class="text-left">{{ item.jk }}</td>
                     </tr>
                     <tr role="row" class="">
                        <td class="text-left">TTL</td>
                        <td class="text-left">{{ item.tempat_lahir }}, {{ item.tgl_lahir | moment("DD MMMM YYYY") }}
                        </td>
                     </tr>
                     <tr role="row" class="">
                        <td class="text-left">Alamat</td>
                        <td class="text-left">{{ item.alamat }}</td>
                     </tr>
                     <tr role="row" class="">
                        <td class="text-left">Telp</td>
                        <td class="text-left">{{ item.telp }}</td>
                     </tr>
                     <tr role="row" class="">
                        <td class="text-left">Email</td>
                        <td class="text-left">{{ item.email }}</td>
                     </tr>
                     <tr role="row" class="">
                        <td class="text-left">Warganegara</td>
                        <td class="text-left">{{ item.warganegara }}</td>
                     </tr>
                  </tbody>
               </table>
            </b-card>

         </b-col>
         <b-col lg="6" md="12">
            <b-card title="Data Permohonan">
               <table class="table b-table table-sm">
                  <tbody>
                     <tr role="row" class="">
                        <td class="text-left">Register</td>
                        <td class="text-left"><b>{{ item.register }}</b>
                        </td>
                     </tr>
                     <tr role="row" class="">
                        <td class="text-left">Tanggal Register</td>
                        <td class="text-left">{{ item.tgl_reg | moment("DD MMMM YYYY")
                           }} | {{ item.time_reg }}</td>
                     </tr>
                     <tr role="row" class="">
                        <td class="text-left">Jenis Permohonan</td>
                        <td class="text-left">{{ item.jenis_permohonan }}</td>
                     </tr>
                     <tr role="row" class="">
                        <td class="text-left">Jenis Izin</td>
                        <td class="text-left"><b>{{ item.jenis_izin }}</b></td>
                     </tr>
                     <tr role="row" class="">
                        <td class="text-left">Nomor SIP</td>
                        <td class="text-left">{{ item.no_sip }}</td>
                     </tr>
                     <tr role="row" class="">
                        <td class="text-left">Terbit SIP</td>
                        <td class="text-left">{{ item.tgl_terbit | moment("DD MMMM YYYY") }}
                        </td>
                     </tr>
                     <tr role="row" class="">
                        <td class="text-left">Periode SIP</td>
                        <td class="text-left">{{ item.tgl_sip_awal | moment("DD MMMM YYYY") }} s/d {{ item.tgl_sip_akhir
                           |
                           moment("DD MMMM YYYY") }}
                        </td>
                     </tr>
                     <tr role="row" class="">
                        <td class="text-left">Pengajuan SIP ke</td>
                        <td class="text-left">{{ item.pengajuan_ke || '-' }}</td>
                     </tr>
                     <tr role="row" class="">
                        <td class="text-left">Nama Fasyankes</td>
                        <td class="text-left"><b>{{ item.nama_fayankes }}</b></td>
                     </tr>
                     <tr role="row" class="">
                        <td class="text-left">Kategori Fasyankes</td>
                        <td class="text-left">{{ item.kategori_fayankes }}</td>
                     </tr>
                     <tr role="row" class="">
                        <td class="text-left">Alamat Fasyankes</td>
                        <td class="text-left">{{ item.alamat_fayankes }}</td>
                     </tr>
                     <tr role="row" class="">
                        <td class="text-left">Telp Fasyankes</td>
                        <td class="text-left">{{ item.telp_fayankes }}</td>
                     </tr>
                     <tr role="row" class="">
                        <td class="text-left">Email Fasyankes</td>
                        <td class="text-left">{{ item.email_fayankes }}</td>
                     </tr>
                     <tr role="row" class="">
                        <td class="text-left">Nomor Izin Sebelumnya</td>
                        <td class="text-left">{{ item.no_izin_before || '-' }}</td>
                     </tr>
                     <tr role="row" class="">
                        <td class="text-left">Masa Berlaku Izin Sebelumnya</td>
                        <td class="text-left">{{ item.berlaku_izin_before || '-' }}</td>
                     </tr>
                     <tr role="row" class="">
                        <td class="text-left">No. STR</td>
                        <td class="text-left">{{ item.no_str }}</td>
                     </tr>
                     <tr role="row" class="">
                        <td class="text-left">Status STR</td>
                        <td class="text-left">{{ item.status_str }}</td>
                     </tr>
                     <tr role="row" class="">
                        <td class="text-left">Status Tujuan Fasyankes</td>
                        <td class="text-left">{{ item.status_fayankes }}</td>
                     </tr>
                     <tr role="row" class="">
                        <td class="text-left">Status Kecukupan SKP</td>
                        <td class="text-left">{{ item.status_skp || '-' }}</td>
                     </tr>
                     <tr role="row" class="">
                        <td class="text-left">Kesimpulan</td>
                        <td class="text-left"><b>{{ item.kesimpulan }}</b></td>
                     </tr>

                  </tbody>
               </table>

            </b-card>

         </b-col>
         <b-col lg="6" md="12">
            <b-card title="Histori Proses">
               <app-timeline class="mt-2 mb-2">
                  <app-timeline-item v-for="(histori, index) in item.Histori" :key="item.id" variant="success">
                     <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                        <!-- <h5>
                           <b>{{ index + 1 }}</b>
                        </h5> -->
                        <b-avatar variant="secondary" size="30" class="shadow mb-1">
                           <b>{{ index + 1 }}</b>
                        </b-avatar>
                     </div>
                     <template v-for="(itemHistori, i) in histori.deskripsi.split('|')">
                        <small :key="i">
                           <b>{{ itemHistori }}</b>
                        </small>
                        <br>
                     </template>
                     <!-- <small>{{ item.end_date | moment("HH:mm:ss | DD MMMM YYYY ") }}</small> -->
                  </app-timeline-item>
               </app-timeline>

            </b-card>

         </b-col>
      </b-row>
   </div>
</template>

<style>
.hidden_header {
   display: none;
}
</style>
